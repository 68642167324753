<script lang="ts">
	import { run } from 'svelte/legacy';

	import { createCheckbox, melt } from '@melt-ui/svelte';
	import { Check, Minus } from 'lucide-svelte';

	let {
		required = false,
		id = '',
		name = '',
		error = $bindable(false),
		checked = $bindable(false),
		children
	}: Props = $props();

	const {
		states: { checked: checkValue },
		elements: { root, input },
		helpers: { isChecked, isIndeterminate }
	} = createCheckbox({
		onCheckedChange: ({ next }) => {
			if (typeof next === 'boolean') {
				checked = next;
			}
			return next;
		}
	});

	interface Props {
		required?: boolean;
		id?: string;
		name?: string;
		error?: boolean;
		checked: any;
		children?: import('svelte').Snippet;
	}

	$effect(() => {
		if (checked !== $checkValue) {
			$checkValue = checked;
		}
	});
</script>

<div class="flex">
	<button
		{id}
		{name}
		class:error
		use:melt={$root}
		class="flex items-center justify-center w-6 h-6 text-black rounded-md shadow appearance-none stroke-2 bg-zinc-100 data-[state='unchecked']:hover:opacity-75 hover:text-white data-[state='checked']:hover:bg-gradient-to-t from-orange-500 to-orange-300 flex-shrink-0"
	>
		{#if $isIndeterminate}
			<Minus class="w-5 h-5" />
		{:else if $isChecked}
			<Check class="w-5 h-5 " />
		{/if}
		<input use:melt={$input} />
	</button>
	<span class="flex mx-2 min-h-6">{@render children?.()}</span>
</div>

<style lang="scss">
	.error {
		border-color: #f07575;
		&:focus {
			outline: none;
			border-color: rgb(250, 69, 69);
			box-shadow: 0 0 0 3px rgba(235 97 97 / 0.3);
		}
	}
</style>
